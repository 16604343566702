import React, { useContext } from "react"
import tw from "twin.macro"
import { Image } from "../../atoms/Image"
import { HeroWithDescriptionProps } from "./Heros.d"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

const HeroWithDescription: React.FC<HeroWithDescriptionProps> = ({
  heading,
  description,
  image,
}) => {
  const [state, dispatch] = useContext(DisclaimersContext)
  return (
    <section
      css={[
        tw`flex flex-col items-center content-center max-w-screen-xl mx-auto py-4 px-8`,
        tw`lg:(gap-16 flex-row py-12 px-4 mt-8)`,
      ]}
    >
      {image && (
        <Image imageData={image?.image} css={tw`w-full max-w-[420px]`} />
      )}
      <div css={tw`flex flex-col justify-center`}>
        {heading && (
          <h1 css={[tw`text-5xl mb-8`, "line-height: 3.5rem"]}>
            {parseDisclaimerBlocks(heading, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h1>
        )}
        {description && (
          <p css={tw`font-light text-2xl`}>
            {parseDisclaimerBlocks(description, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </p>
        )}
      </div>
    </section>
  )
}

export default HeroWithDescription

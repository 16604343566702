import React, { useContext, useEffect, useState } from "react"
import tw from "twin.macro"
import useViewportSize from "../../../hooks/useViewportSize"
import { ButtonLink } from "../../atoms/Button"
import { Image } from "../../atoms/Image"
import { ImageWithAlt } from "../Callout/Callout.d"
import { FullWidthHeroSectionProps } from "./Heros.d"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { parseDisclaimerBlocks } from "../../../helpers"
import { DisclaimersContext } from "../../../contexts/Disclaimers"

const FullWidthHeroSection: React.FC<FullWidthHeroSectionProps> = ({
  backgroundImage,
  backgroundImageMobile,
  ctas,
  heading,
}) => {
  const [backgroundImageSrc, setBackgroundImageSrc] = useState<ImageWithAlt>()
  const { width } = useViewportSize()

  useEffect(() => {
    if (width <= 768 && backgroundImageMobile?.image) {
      setBackgroundImageSrc(backgroundImageMobile)
    } else {
      setBackgroundImageSrc(backgroundImage)
    }
  }, [width])
  const [state, dispatch] = useContext(DisclaimersContext)
  return (
    <section css={tw`relative h-[680px]`}>
      {backgroundImageSrc && (
        <>
          <Image
            imageData={backgroundImageSrc.image}
            css={[
              tw`absolute top-0 w-full h-full w-full object-cover`,
              "z-index: -1;",
            ]}
            width={1400}
          />
          <div
            css={[
              tw` w-full h-full absolute top-0 bg-black mix-blend-normal opacity-25	`,
              "z-index: -1;",
              "filter: blur(44.8517px);",
              "transform: matrix(1, 0, 0, -1, 0, 0);",
            ]}
          ></div>
        </>
      )}
      <div
        css={[
          tw`h-full flex flex-col justify-center px-4`,
          tw`sm:(px-8)`,
          tw`md:(px-16)`,
        ]}
      >
        {heading && (
          <h1
            css={[
              tw`text-white text-5xl mb-4`,
              tw`lg:(w-2/3)`,
              "line-height: 3.75rem",
            ]}
          >
            {parseDisclaimerBlocks(heading, selection =>
              dispatch(toggleDisclaimersModal(selection))
            )}
          </h1>
        )}
        <div>
          {/* CTAs */}
          {ctas && (
            <div
              css={[tw`mt-6`, ctas?.length > 1 && tw`flex grid-cols-2 gap-2`]}
            >
              {ctas?.map((cta: any) => (
                <ButtonLink
                  {...{ [cta?.buttonType]: true }}
                  to={
                    cta?.externalUrl || `/${cta?.internalLink?.slug?.current}`
                  }
                  css={[tw`whitespace-nowrap`, tw`lg:w-auto`]}
                  aria-label={cta?.title}
                  key={cta?.title}
                >
                  {cta?.title}
                </ButtonLink>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default FullWidthHeroSection

import { graphql, navigate } from "gatsby"
import { Layout } from "../components/atoms/Layout"
import React, { useContext, useEffect, useRef } from "react"
import tw from "twin.macro"
import BlockContent from "../components/atoms/BlockContent"
import { SEO } from "../components/atoms/SEO"
import { DefaultTemplatePageData } from "./templates"
import { Pixlee } from "../components/molecules/Pixlee"
import { DealersNearYou } from "../components/organisms/DealersNearYou"
import {
  FullWidthHeroSection,
  HeroWithDescription,
} from "../components/molecules/Heros"
import useTealiumView from "../hooks/Tealium/useTealiumView"
import { ImageBlocksWithDescription } from "../components/molecules/ImageBlocksWithDescription"
import SeriesSelection from "../components/organisms/Homepage/SeriesSelection/SeriesSelection"
import { PageLinksSection } from "../components/molecules/PageLinksSection"
import { Back } from "../components/atoms/Back"
import _ from "lodash"
import { LanguageContext } from "../contexts/Language"
import { LocationContext } from "../contexts/Location"
import useTealiumNoZip from "../hooks/Tealium/useTealiumNoZip"
import useDealers from "../hooks/useDealers"
import { ImageBlocks } from "../components/organisms/Series"

const DefaultPage: React.FC<DefaultTemplatePageData> = ({ data, location }) => {
  const { page } = data
  const { handleTealView } = useTealiumView()
  const [{ dealers }] = useContext(LocationContext)
  const pageload = useRef(true)
  const { getDealerInfo } = useDealers()
  const [isOutOfArea] = useTealiumNoZip()

  // Page load event for pages with Dealers Near You section
  useEffect(() => {
    const hasDealersNearYou = page?.content?.find(
      section => section._type === "dealersNearYouSection"
    )
    if (!page?.tagging || !hasDealersNearYou) return
    const dealerInfo = getDealerInfo()
    /* pageload.current = Fire only on inital page load, and not every time dealers is updated */

    // "If no zip is set because visitor is from out of area"
    if (isOutOfArea && pageload.current) {
      pageload.current = false
      handleTealView({
        page_name: page.tagging?.pageName,
        page_type: page.tagging?.pageType,
        tealium_event: page.tagging?.tealiumEvent,
        search_results: 0,
        dealer_code: "NOT SET",
        dealer_name: "NOT SET",
      })
    }

    if (pageload.current && dealers) {
      pageload.current = false
      handleTealView({
        page_name: page.tagging?.pageName,
        page_type: page.tagging?.pageType,
        tealium_event: page.tagging?.tealiumEvent,
        search_results: dealers?.length,
        dealer_code: dealerInfo.dealerCodes,
        dealer_name: dealerInfo.dealerNames,
      })
    }
  }, [dealers, isOutOfArea])

  // Page load event for pages without Dealers Near You Section
  useEffect(() => {
    const hasDealersNearYou = page?.content?.find(
      section => section._type === "dealersNearYouSection"
    )
    if (!hasDealersNearYou && page?.tagging) {
      let tealData = {
        page_name: page.tagging?.pageName,
        page_type: page.tagging?.pageType,
        tealium_event: page.tagging?.tealiumEvent,
      }
      pageload.current = false
      handleTealView(tealData)
    }
  }, [])

  return (
    <Layout>
      <SEO
        url={location.href}
        title={page.title}
        keywords={page.seo?.keywords}
        schema={page.seo?.schema}
        description={page.seo?.description}
        canonicalPath={page.seo?.canonicalPath}
      />

      <SanitySections data={page.content} />
    </Layout>
  )
}

export default DefaultPage

const SanitySections = ({ data }: any): JSX.Element => {
  const { _ } = useContext(LanguageContext)
  return (
    <>
      {data?.map((section: any) => {
        switch (section?._type) {
          case "generalContent":
            return (
              <div
                css={[
                  tw`max-w-desktop py-10 mx-auto text-lg pb-14`,
                  tw`lg:(px-10)`,
                ]}
              >
                <BlockContent data={section.body} />
              </div>
            )
          case "heroWithDescription":
            return <HeroWithDescription {...section} />
          case "fullWidthHeroSection":
            return <FullWidthHeroSection {...section} />
          case "pixleeAlbum":
            return <Pixlee {...section} />
          case "dealersNearYouSection":
            return <DealersNearYou type="default" {...section} />
          case "imageBlocks":
            const imageBlocksData = section?.callouts
            return (
              <ImageBlocks
                imageBlocks={imageBlocksData}
                _key={"Image Blocks"}
                sectionSlug={{
                  current: "",
                }}
              />
            )
          case "imageBlocksWithDescriptionSection":
            return (
              <ImageBlocksWithDescription
                section={section}
                analyticsIdArrows="other pages:other pages"
              />
            )
          case "seriesSelection":
            return (
              <SeriesSelection
                {...section}
                analyticsIdArrows="vehicles:vehicles"
                css={tw`py-12`}
              />
            )
          case "linksSection":
            return <PageLinksSection {...section} />
          case "backButton":
            return (
              <div css={[tw`max-w-[1440px]  mx-auto pl-4 mt-8`]}>
                <Back cta={`${_("Back")}`} link={null} />
              </div>
            )
        }
      })}
    </>
  )
}

export const query = graphql`
  query PageQuery($id: String) {
    page: sanityPage(id: { eq: $id }) {
      id
      language
      _type
      title
      seo {
        keywords
        schema
        description
        canonicalPath
      }
      tagging {
        pageName
        pageType
        tealiumEvent
      }
      content {
        ...GeneralContentData
        ...HeroWithDescription
        ...FullWidthHeroSection
        ...DealersNearYouData
        ...PixleeSectionData
        ...ImageBlocksData
        ...ImageBlocksWithDescriptionData
        ...SeriesSelectionData
        ... on SanityBackButton {
          _type
          includeBackButton
        }
        ...PageLinksSection
      }
      disclaimers {
        code
        disclaimer
      }
    }
  }
`

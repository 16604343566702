import React from "react"
import tw from "twin.macro"
import { ThreeArrowAnimation } from "../ThreeArrowAnimation"
import { PageLinksSectionProps } from "./PageLinksSection.d"
import { Link } from "../../atoms/Link"

const PageLinksSection: React.FC<PageLinksSectionProps> = ({ links }) => {
  return (
    <section
      css={[
        tw`grid max-w-desktop py-12 mx-auto`,
        links?.length === 3 && tw`grid-cols-3 justify-between`,
        links?.length === 2 && tw`grid-cols-2 justify-between`,
      ]}
    >
      {links.map((link, i) => (
        <div css={[i !== links.length - 1 && tw`border-r-2 border-gray-400`]}>
          <Link
            className="group"
            to={
              link?.anchor
                ? `#${link?.anchor}`
                : link?.externalUrl || `/${link?.internalLink?.slug?.current}`
            }
            target={link?.externalUrl ? "_blank" : "_self"}
            css={[
              tw`p-4 font-semibold text-xl text-center flex flex-col content-center justify-center max-w-[11.75rem] mx-auto sm:flex-row md:text-2xl`,
              tw`focus-visible:(outline-gray)`,
            ]}
          >
            <span dangerouslySetInnerHTML={{ __html: link?.displayText }} />
            <span css={tw`relative w-10 h-5 -ml-3 sm:mr-0 sm:ml-2 sm:w-auto `}>
              <ThreeArrowAnimation large />
            </span>
          </Link>
        </div>
      ))}
    </section>
  )
}

export default PageLinksSection
